<template>
    <div class="flex-box buddhism-bench">
        <div class="flex-grow flex-box vertical">
            <div class="piece-area buddhism-my" v-if="admin">
                <div class="piece-area-title">总览</div>
                <div class="piece-area-ctx flex-box align-center">
                    <div class="buddhism-order-view">
                        <div class="buddhism-order-title">待审核</div>
                        <div class="buddhism-order-num">{{count.not_verify}}</div>
                    </div>
                    <div class="buddhism-order-view">
                        <div class="buddhism-order-title">待派单</div>
                        <div class="buddhism-order-num">{{count.not_distribute}}</div>
                    </div>
                </div>
            </div>
            <div class="piece-area buddhism-my" v-else>
                <div class="piece-area-title">总览</div>
                <div class="piece-area-ctx flex-box align-center">
                    <div class="buddhism-order-view">
                        <div class="buddhism-order-title">待开牌</div>
                        <div class="buddhism-order-num">{{count.not_kp}}</div>
                    </div>
                    <div class="buddhism-order-view">
                        <div class="buddhism-order-title">已开牌</div>
                        <div class="buddhism-order-num">{{count.kp}}</div>
                    </div>
                </div>
            </div>
            <buddhism-enroll-list ref="buddhismList" :type="admin ? 'claimed' : 'open'" class="piece-area buddha-order-list"></buddhism-enroll-list>
            <notice-list class="piece-area" style="margin-top: 16px;" simple show-time v-if="!admin"></notice-list>
        </div>
        <div class="flex-box vertical buddhism-bench-right" v-if="admin">
            <a-card :bordered="false" class="flex-grow buddhism-bench-card" :body-style="{padding: 0}" title="认领台">
                <router-link slot="extra" :to="{name: 'BuddhismClaim'}">查看更多<a-icon type="right" /></router-link>
                <div class="buddhism-claim-list" v-if="claimList && claimList.length > 0">
                    <div class="buddhism-claim-item" v-for="b in claimList" :key="b.id">
                        <div class="buddhism-item-name">{{b.temp ? b.temp.name : '--'}}</div>
                        <div class="buddhism-item-way">{{b.wayText}}</div>
                        <div class="buddhism-item-btn"><span class="txt-btn" @click="showDetail(b)">查看</span></div>
                    </div>
                </div>
                <a-empty :image="emptyImage" v-else-if="claimList"/>
            </a-card>
            <a-card :bordered="false" class="flex-grow buddhism-bench-card" :body-style="{padding: 0}" title="未读消息" style="margin-top: 16px">
                <router-link slot="extra" :to="{name: 'BuddhaNotice'}">查看更多<a-icon type="right" /></router-link>
                <notice-list simple></notice-list>
            </a-card>
            <a-card :bordered="false" class="buddhism-bench-card" title="线下登记" style="margin-top: 16px">
                <router-link slot="extra" :to="{name: 'BuddhismAdd'}">去登记<a-icon type="right" /></router-link>
            </a-card>
        </div>
    </div>
</template>

<script>
    import BuddhismEnrollList from "./BuddhismEnrollList";
    import NoticeList from "../../layouts/NoticeList";
    import {isBuddhaAdmin} from "../../common/constant/org";
    import {getUserWxId,getTemple} from "../../common/js/storage";
    import { Empty } from 'ant-design-vue';
    import {getBuddhismWayText} from "../../common/buddha/buddhism";

    export default {
        name: "BuddhismWorkbench",
        components: {
            NoticeList,
            BuddhismEnrollList
        },
        data() {
            return {
                admin: isBuddhaAdmin(),
                claimList: null,
                emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
                count: {
                    kp: 0,
                    not_distribute: 0,
                    not_kp: 0,
                    not_verify: 0
                }
            }
        },
        created() {
            if(this.admin) {
                this.getClaimList();
            }
            this.getData();
        },
        methods: {
            getData() {
                let url = '/admin/fs/get-count';
                this.$axios(url).then(res => {
                    if(res.error == 0) {
                        this.count = res.data
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
            },
            claimBuddhism(b) {
                this.$confirm({
                    title: '提示',
                    content: '确定认领吗？',
                    onOk: () => {
                        let url = `/admin/fs/${b.id}`;
                        let wxId = getUserWxId();
                        this.$axios({
                            url,
                            method: 'PATCH',
                            data: {
                                user_id: wxId,
                                status: 2,
                                flow: JSON.stringify([{type: 1, title: '已认领', user_id: wxId, time: new Date().pattern("yyyy-MM-dd: HH:mm:ss")}])
                            }
                        }).then(() => {
                            this.$message.success("已认领");
                            this.getClaimList();
                            this.$refs.buddhismList.getList();
                        });
                    },
                });
            },
            showDetail(b) {
                this.$router.push({name: 'BuddhismDetail', params: {id: b.id}});
            },
            getClaimList() {
                let url = `/admin/fs?sort=-id&expand=temp&pageSize=10&status=1&temple_id=${getTemple()}&is_rl=1`;
                this.$axios(url, {noTempleFilter: true}).then(res => {
                    res.data.forEach(item => {
                        item.wayText = getBuddhismWayText(item);
                    })
                    this.claimList = res.data;
                });
            }
        }
    }
</script>

<style scoped lang="less">
    .buddhism-bench {
        flex-shrink: 0;
        margin: 16px auto;
        width: 1400px;
        min-height: 818px;
    }
    .piece-area {
        padding: 16px;
        background-color: @component-background;
        box-shadow: @box-shadow-base;
        &.buddha-order-list {
            flex: 1;
        }
    }
    .piece-area-title {
        padding-bottom: 16px;
        border-bottom: var(--border);
        font-size: 16px;
        line-height: 1;
    }
    .buddhism-order-view {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px 0;
        align-items: center;
        font-size: 16px;
    }
    .buddha-order-list {
        margin: 16px 0 0;
        width: auto;
    }
    .buddhism-bench-right {
        flex-shrink: 0;
        margin-left: 16px;
        width: 360px;
    }
    .buddhism-register {
        margin-top: 16px;
    }
    .buddhism-bench-card {
        box-shadow: @box-shadow-base;
    }
    .buddhism-claim-item {
        display: flex;
        align-items: center;
        padding: 8px 24px;
        &:hover {
            background-color: @background-color-light;
        }
    }
    .buddhism-item-name {
        flex: 1;
    }
    .buddhism-item-way {
        width: 120px;
    }
</style>
