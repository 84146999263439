<template>
  <div class="flex-box vertical buddha-notice">
    <div class="data-title" v-if="!simple">
      <div class="title-left flex-box">
        <span>共{{pagination.total}}条消息</span>
      </div>
      <a-form-model class="query-form" layout="inline">
        <a-form-model-item label="">
          <a-select
            style="width: 180px"
            placeholder="选择状态筛选"
            @change="getList"
            :options="statusList"
            v-model="form.is_read">
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <a-space class="title-btn">
        <a-button @click="getList">查询</a-button>
      </a-space>
    </div>
    <div class="flex-grow scroll-area">
      <div class="notice-list" v-if="list && list.length > 0">
        <div class="notice-item" :class="{'notice-unread': n.is_read != 1}" v-for="(n, i) in list" :key="i">
          <a-icon type="file-text" />
          <div class="notice-title">{{n.content}}</div>
          <div class="notice-more" @click="showDetail(n)">
            <span>{{showTime ? n.create_time : '查看详情'}}</span>
            <a-icon type="right" />
          </div>
        </div>
      </div>
      <a-empty :image="emptyImage" description="暂无消息" v-else-if="list" />
    </div>
    <a-pagination v-bind="pagination" @change="handlePageChange" style="margin-top: 10px;text-align: right" v-if="!simple"/>
  </div>
</template>

<script>
    import { Empty } from 'ant-design-vue';
    import {getStorageInfo, getUserWxId} from "../common/js/storage";
    import {isEmpty} from "../common/js/tool";
    export default {
        name: "NoticeList",
        props: {
            simple: Boolean,
            showTime: Boolean
        },
        data() {
            return {
                emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
                list: null,
                form: {is_read: 0},
                statusList: [
                    {key: -1, title: '全部'},
                    {key: 0, title: '未读'},
                    {key: 1, title: '已读'},
                  ],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10
                },
                privilege: 0,
            }
        },
        created() {
            this.setPrivilege();
            this.getList();
        },
        methods: {
            showDetail(n) {
                this.$axios({
                    url: `/admin/fs-notice/${n.id}`,
                    method: 'PATCH',
                    data: {
                        is_read: 1
                    }
                }).then(() => {
                    this.getList();
                });
                this.$router.push({name: 'BuddhismDetail', params: {id: n.fs_id}});
            },
            setPrivilege() {
                let user = getStorageInfo();
                let privilege = 0;
                if(user.role == 1) {
                    privilege = 1;
                } else if(user.userRole && user.userRole.length > 0) {
                    let rl = user.userRole.filter(r => r.system == 5);
                    if(rl.length > 0) {
                        rl.sort((a, b) => a.privilege - b.privilege);
                        privilege = rl[0].privilege;
                    }
                }
                this.privilege = privilege;
            },
            getList() {
                let privilege = this.privilege;
                if(privilege == 0) return;
                let url = '/admin/fs-notice?sort=-id';
                if(this.simple) {
                    url += '&pageSize=6&filter[is_read]=0';
                } else {
                    let {current, pageSize} = this.pagination;
                    url += `&page=${current}&pageSize=${pageSize}`;
                    let is_read = this.form.is_read;
                    if(!isEmpty(is_read) && is_read != -1) {
                        url += `&filter[is_read]=${is_read}`;
                    }
                }
                switch (privilege) {
                    case 1:
                        url += `&filter[or][0][type]=4&filter[or][1][type]=5&filter[or][2][type]=6`;
                        break;
                    case 2:
                    case 3:
                        url += `&filter[user_id]=${getUserWxId()}`;
                        break;
                }
                this.$axios(url).then(res => {
                    this.list = res.data;
                    this.$set(this.pagination, 'total', res.page.totalCount)
                });
            },
            handlePageChange(page) {
                let pager = { ...this.pagination };
                pager.current = page.current;
                this.pagination = pager;
                this.getList();
            },
        }
    }
</script>

<style scoped lang="less">
  .notice-list {
    padding: 16px 0;
  }
  .notice-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 24px;
    height: 36px;
    &:hover {
      background-color: @background-color-light;
    }
    &.notice-unread::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 12px;
      margin: auto 0;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: @primary-color;
    }
  }
  .notice-title {
    flex: 1;
    margin: 0 8px;
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .notice-more {
    color: @primary-color;
    cursor: pointer;
  }
</style>
