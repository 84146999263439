<template>
    <div class="content md flex-box vertical">
        <div class="data-title">
            <div class="title-left flex-box">
                <span>共{{pagination.total}}条记录</span>
            </div>
            <a-form-model class="query-form" layout="inline">
                <a-form-model-item label="类型" v-if="admin">
                    <a-select
                            style="width: 180px"
                            placeholder="选择类型筛选"
                            @change="getList"
                            allowClear
                            :options="typeList"
                            v-model="form.type">
                    </a-select>
                </a-form-model-item>
                <template v-if="type != 'claim'">
                    <a-form-model-item label="状态">
                        <a-select
                          style="width: 180px"
                          placeholder="选择状态筛选"
                          @change="getList"
                          allowClear
                          :options="statusList"
                          v-model="form.status">
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="是否支付">
                        <a-select
                          style="width: 180px"
                          placeholder="选择是否支付筛选"
                          @change="getList"
                          allowClear
                          :options="payStatusList"
                          v-model="form.payStatus">
                        </a-select>
                    </a-form-model-item>
                </template>
            </a-form-model>
            <a-space class="title-btn">
                <a-button @click="getList">查询</a-button>
                <a-button type="primary" @click="$router.push({name: 'BuddhismAdd'})" v-if="!type">线下登记</a-button>
            </a-space>
        </div>
        <div class="data-area">
            <a-table
                    ref="dataTable"
                    :columns="columns"
                    row-key="id"
                    :data-source="list"
                    :loading="loading"
                    :pagination="pagination"
                    :scroll="{y: h}"
                    @change="handleTableChange">
                <template v-slot:action="text, record">
                    <div class="row-btn">
                        <a class="txt-btn" @click.stop="showDetail(record)">查看</a>
<!--                        <a class="txt-btn" @click="claimBuddhism(record)" v-if="record.status == 1 && canClaimBuddhism(record, admin)">认领</a>-->
                        <a class="txt-btn danger" @click.stop="delBuddhism(record)" v-if="!type && admin">删除</a>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
    import {buddhaTypeList, getBuddhaTypeName} from "../../common/buddha/type";
    import {
        buddhismStates,
        getBuddhismStatusText,
        canClaimBuddhism,
        getBuddhismWayText, getBuddhismOrderStatusText
    } from "../../common/buddha/buddhism";
    import {isBuddhaAdmin} from "../../common/constant/org";
    import {getUserWxId,getTemple} from "../../common/js/storage";
    const fsTypes = [1,2,3,13,14,15,16,17];

    export default {
        name: "BuddhismEnrollList",
        props: {
            type: String // claim待认领列表 claimed我的认领 open开牌列表 不传显示所有登记列表
        },
        data() {
            return {
                loading: false,
                list: [],
                form: {},
                h: 500,
                typeList: buddhaTypeList.filter(item => fsTypes.indexOf(item.key) >= 0).map(item => {return {key: item.key, title: item.title}}),
                columns: [
                    {title: '提交时间', dataIndex: 'create_time'},
                    {title: '类型', dataIndex: 'typeText'},
                    {title: '佛事状态', dataIndex: 'statusText'},
                    {title: '订单状态', dataIndex: 'orderStatus'},
                    {title: '佛事时间', dataIndex: 'buddhaTime', width: 200},
                    {
                        title: '审核人',
                        dataIndex: 'user_id',
                        customRender: text => {
                            return text ? <open-data type="userName" openid={text} /> : <span>--</span>
                        }
                    },
                    {title: '提交方式', dataIndex: 'wayText'},
                    {title: '操作', key: 'action', fixed: 'right', scopedSlots: { customRender: 'action'}}
                ],
                payStatusList: [
                    {key: 1, title: '未支付'},
                    {key: 2, title: '已支付'},
                ],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                admin: isBuddhaAdmin()
            }
        },
        computed: {
            statusList() {
                let admin = this.admin, type = this.type;
                let res = [];
                if(type !== 'claim') {
                    if(admin) {
                        res = [...buddhismStates];
                    } else {
                        res = [
                            {key: 9, title: '待开牌'},
                            {key: 10, title: '已开牌'},
                        ]
                    }
                }
                return res;
            },
            query() {
                let res = "";
                let type = this.type;
                // 认领列表
                switch (type) {
                    case 'claim':
                        res = '&status=1&is_rl=1';
                        break;
                    case 'claimed':
                        res = `&user_id=${getUserWxId()}`;
                        break;
                    case 'open':
                        res = `&status=9,10,6,8`
                        break;
                }
                return res;
            }
        },
        created() {
            this.getList();
        },
        mounted() {
            this.setHeight();
            window.addEventListener("resize", this.setHeight, false);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.setHeight, false);
        },
        methods: {
            canClaimBuddhism,
            operate(type, record) {
                let fn = this[`${type}Buddhism`];
                if(typeof fn === 'function') {
                    fn(record);
                }
            },
            delBuddhism(record) {
                this.$confirm({
                    title: '提示',
                    content: '删除之后数据不可恢复，确定删除吗？',
                    onOk: () => {
                        let url = `/admin/fs/${record.id}`;
                        this.$axios({
                            url,
                            method: 'DELETE',
                        }).then(() => {
                            this.$message.success("已删除");
                            this.getList();
                        });
                    },
                });
            },
            claimBuddhism(b) {
                this.$confirm({
                    title: '提示',
                    content: '确定认领吗？',
                    onOk: () => {
                        let url = `/admin/fs/${b.id}`;
                        let wxId = getUserWxId();
                        this.$axios({
                            url,
                            method: 'PATCH',
                            data: {
                                user_id: getUserWxId(),
                                status: 2,
                                flow: JSON.stringify([{type: 1, title: '已认领', user_id: wxId, time: new Date().pattern("yyyy-MM-dd: HH:mm:ss")}])
                            }
                        }).then(() => {
                            this.$message.success("已认领");
                            this.$refs.buddhismList.getList();
                        });
                    },
                });
            },
            showDetail(record) {
                this.$router.push({name: 'BuddhismDetail', params: {id: record.id}});
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getList();
            },
            getList() {
                if(this.loading) return;
                this.loading = true;
                let url = `/admin/fs?sort=-id&expand=temp,order,timeRange&temple_id=${getTemple()}`;
                let {current, pageSize} = this.pagination;
                url += `&page=${current}&pageSize=${pageSize}`;
                url += this.query;
                let form = this.form;
                if(form.status > 0) {
                    url += `&status=${form.status}`;
                }
                if(form.type > 0) {
                    url += `&fs_type=${form.type}`;
                } else if(!this.type) {
                    // 所有列表去掉不用登记的类型
                    url += `&fs_type=${fsTypes.join(",")}`
                }
                if(form.payStatus == 1) {
                    url += "&order_status=1,8"
                } else if(form.payStatus == 2) {
                    url += "&order_status=2,7"
                }
                this.$axios(url, {noTempleFilter: true}).then(res => {
                    let list = res.data;
                    this.dealList(list)
                    this.list = list;
                    if(res.page) {
                        this.$set(this.pagination, 'total', res.page.totalCount);
                    }
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            },
            dealList(list) {
                list.forEach(item => {
                    item.typeText = getBuddhaTypeName(item.temp.fs_type);
                    item.statusText = getBuddhismStatusText(item);
                    item.wayText = getBuddhismWayText(item);
                    item.orderStatus = getBuddhismOrderStatusText(item.order?.status) || "--"
                    if(item.timeRange) {
                        item.buddhaTime = `${item.date} ${item.timeRange ? item.timeRange.name : ''}`
                    } else {
                        item.buddhaTime = '--'
                    }
                });
                this.list = list;
            },
            setHeight() {
                let dataTale = this.$refs.dataTable;
                if(dataTale) {
                    let area = this.$el.querySelector(".data-area");
                    this.h = area.clientHeight - 54 - 64;
                }
            },
        }
    }
</script>

<style scoped>

</style>
